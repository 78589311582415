{
  "data": [
    {
      "id": "52bd2a3b-9b7e-4402-845f-d8fefd21b0bb",
      "code": "dallasbrunch",
      "name": "Build A Better You",
      "tagline": "Day Party with a Purpose",
      "location": "Concord Church",
      "address": {
        "street": "6808 Pastor Bailey Dr",
        "city": "Dallas",
        "state": "TX",
        "zip": "75237"
      },
      "micrositeId": "aby",
      "startTimestamp": 1695488400,
      "endTimestamp": 1695497400,
      "startTime": "1:00 p.m.",
      "endTime": "3:00 p.m.",
      "featuring": [
        {
          "name": "Rita Tolbert",
          "details": ""
        },
        {
          "name": "Alicia Brown Young",
          "details": ""
        },
        {
          "name": "music and more!",
          "details": ""
        }
      ],
      "heroImage": "/assets/img/aby_1.jpg",
      "secondaryImage": "/assets/img/hbc.png",
      "textBlock": "Enjoy an afternoon of inspiration and education. Hear transforming testimonies and participate in an uplifting and educational Medicare experience! Live DJ, free food, giveaways, dancing, resources, and more! <span>Complimentary refreshments served.</span>"
    },
    {
      "id": "4e6f7c8d-a1b2-3c4d-e5f6-7a8b9c0d1e2f",
      "code": "livingstones",
      "name": "Build A Better You",
      "tagline": "Day Party with a Purpose",
      "location": "Living Stones Temple Church",
      "address": {
        "street": " 1009 Walker Ave",
        "city": "Fultondale",
        "state": "AL",
        "zip": "35217"
      },
      "micrositeId": "aby",
      "startTimestamp": 1697284800,
      "endTimestamp": 1697292000,
      "startTime": "12:00 p.m.",
      "endTime": "2:00 p.m.",
      "featuring": [
        {
          "name": "Rita Tolbert",
          "details": ""
        },
        {
          "name": "Ernestine Shepherd",
          "details": ""
        },
        {
          "name": "Yohnnie Shamborger",
          "details": ""
        },
        {
          "name": "music and more!",
          "details": ""
        }
      ],
      "heroImage": "/assets/img/aby_1.jpg",
      "secondaryImage": "/assets/img/livingStones.png"
    },
    {
      "id": "1a2b3c4d-5e6f-7a8b-9c1d-2e3f4a5b6c7d",
      "name": "Build A Better You",
      "code": "impact",
      "tagline": "Day Party with a Purpose",
      "location": "IMPACT Church",
      "address": {
        "street": "2323 Sylvan Rd.",
        "city": "East Point",
        "state": "GA",
        "zip": "30344"
      },
      "micrositeId": "aby",
      "startTimestamp": 1697889600,
      "endTimestamp": 1697896800,
      "startTime": "12:00 p.m.",
      "endTime": "2:00 p.m.",
      "featuring": [
        {
          "name": "Rita Tolbert",
          "details": ""
        },
        {
          "name": "Vance Ross",
          "details": ""
        },
        {
          "name": "Paul Thibodeaux",
          "details": ""
        },
        {
          "name": "music and more!",
          "details": ""
        }
      ],
      "heroImage": "/assets/img/aby_1.jpg",
      "secondaryImage": "/assets/img/impact_church.png"
    },
    {
      "id": "a1b2c3d4-e5f6-g7h8-i9j0-k1l2m3n4o5p6",
      "name": "Build A Better You",
      "code": "stjohnsdowntown",
      "tagline": "Day Party with a Purpose",
      "location": "St Johns Downtown",
      "address": {
        "street": "2019 Crawford St.",
        "city": "Houston",
        "state": "Texas",
        "zip": "77002"
      },
      "micrositeId": "aby",
      "startTimestamp": 1698494400,
      "endTimestamp": 1698501600,
      "startTime": "12:00 p.m.",
      "endTime": "2:00 p.m.",
      "featuring": [
        {
          "name": "Rita Tolbert",
          "details": ""
        },
        {
          "name": "Juanita & Rudy Rasmus",
          "details": ""
        },
        {
          "name": "music and more!",
          "details": ""
        }
      ],
      "heroImage": "/assets/img/aby_1.jpg",
      "secondaryImage": "/assets/img/st_johns_town_church.png"
    },
    {
      "id": "f0e1d2c3-b4a5-6f7e-8d9c-b0a1b2c3d4e5",
      "code": "sanfernando",
      "name": "¡Hay Más Adelante!",
      "tagline": "30th San Fernando Health Fair",
      "location": "San Fernando Main Plaza",
      "address": {
        "street": "115 Main Plaza,",
        "city": "San Antonio",
        "state": "TX",
        "zip": "78205"
      },
      "micrositeId": "hma",
      "startTimestamp": 1696665600,
      "endTimestamp": 1696683600,
      "startTime": "8:00 a.m.",
      "endTime": "1:00 p.m.",
      "featuring": [
        {
          "name": "Rita Tolbert",
          "details": ""
        },
        {
          "name": "Ernie Zuniga",
          "details": ""
        },
        {
          "name": "Augie Meyers",
          "details": ""
        }
      ],
      "heroImage": "https://files.supergreatestparty.com/events/hma_1.png",
      "secondaryImage": "https://files.supergreatestparty.com/events/San_Fernando_Health_Fair.png",
      "textBlock": "Enjoy a morning of inspiration and education. Hear transforming testimonies and participate in an uplifting and educational Medicare experience! Free food, giveaways, resources, and more! <span>Complimentary refreshments served.</span>"
    },
    {
      "micrositeId": "aby",
      "code": "baltimoreevent",
      "name": "The Healing Power of Cultural Food",
      "tagline": "",
      "location": "Reginald F. Lewis Museum",
      "address": {
        "street": "830 East Pratt Street",
        "city": "Baltimore",
        "state": "MD",
        "zip": "21202"
      },
      "startTimestamp": 1720309200,
      "endTimestamp": 1720316400,
      "sessions": [
        {
          "name": "Morning Session",
          "times": "Registration 9:00 a.m."
        },
        {
          "name": "Afternoon Session",
          "times": "Registration 12:30 p.m."
        }
      ],
      "featuring": [
        {
          "name": "Rita Tolbert"
        }
      ],
      "heroImage": "https://files.supergreatestparty.com/events/aby_2024.jpg",
      "textBlock": "",
      "id": "2b7c9f8e-5a1d-4d9f-9a4c-0e3b7d1c6a9f"
    },
    {
      "micrositeId": "aby",
      "code": "losangeles",
      "name": "The Healing Power of Cultural Food",
      "tagline": "",
      "location": "Holman UMC",
      "address": {
        "street": "3320 W. Adams Blvd.",
        "city": "Los Angeles",
        "state": "CA",
        "zip": "90018"
      },
      "startTimestamp": 1726894800,
      "endTimestamp": 1726894800,
      "sessions": [
        {
          "name": "Morning Session",
          "times": "9:30 a.m. - 11:00 a.m."
        },
        {
          "name": "Afternoon Session",
          "times": "1:00 p.m. - 2:30 p.m."
        }
      ],
      "featuring": [
        {
          "name": "Rita Tolbert"
        }
      ],
      "heroImage": "https://files.supergreatestparty.com/events/aby_2024.jpg",
      "textBlock": "",
      "id": "8a9e1b7c-3d4f-49c8-9d5a-0f2c6b7a9e3d",
      "flyer": "https://files.supergreatestparty.com/events/Baltimore.pdf"
    },
    {
      "micrositeId": "aby",
      "code": "newyork",
      "name": "The Healing Power of Cultural Food",
      "tagline": "",
      "location": "Jamaica Center for Arts and Learning",
      "address": {
        "street": "161-4 Jamaica Ave.",
        "city": "Queens",
        "state": "NY",
        "zip": "11432"
      },
      "startTimestamp": 1728709200,
      "endTimestamp": 1728709200,
      "startTime": "TBD",
      "endTime": "TBD",
      "featuring": [
        {
          "name": "Rita Tolbert"
        }
      ],
      "heroImage": "https://files.supergreatestparty.com/events/aby_2024.jpg",
      "textBlock": "",
      "id": "4c1f9d8e-7b2d-4a9c-9b3f-0a5d7e1c6f3b",
      "flyer": "https://files.supergreatestparty.com/events/Baltimore.pdf"
    },
    {
      "micrositeId": "hma",
      "code": "hmalosangeles",
      "name": "The Healing Power of Hispanic Food",
      "tagline": "",
      "location": "The Colony Theatre",
      "address": {
        "street": "555 N. Third St.",
        "city": "Burbank",
        "state": "CA",
        "zip": "91502"
      },
      "startTimestamp": 1001653200,
      "endTimestamp": 1001653200,
      "startTime": "9:30 a.m.",
      "endTime": "1:00 p.m.",
      "dateString": "Saturday, September 28",
      "featuring": [
        {
          "name": "Dana Stanglmaier",
          "details": ""
        }
      ],
      "sessions":[
        {
          "name": "Morning Session",
          "times": "Registration 10:00 a.m."
        },
        {
          "name": "Afternoon Session",
          "times": "Registration 2:30 p.m."
        }
      ],
      "heroImage": "https://files.supergreatestparty.com/events/hma_2024.jpg",
      "secondaryImage": "",
      "textBlock": "",
      "id": "9b7d1a8c-2e3f-49c4-9d5b-6a0e3c7b9f1d",
      "flyer": "https://files.supergreatestparty.com/events/Baltimore.pdf"
    },
    {
      "micrositeId": "hma",
      "code": "sanantonio",
      "name": "The Healing Power of Hispanic Food",
      "tagline": "",
      "location": "TBD",
      "address": {
        "street": "TBD",
        "city": "TBD",
        "state": "TBD",
        "zip": "TBD"
      },
      "startTimestamp": 1728104400,
      "endTimestamp": 1728104400,
      "startTime": "TBD",
      "endTime": "TBD",
      "featuring": [
        {
          "name": "Dana Stanglmaier",
          "details": ""
        }
      ],
      "heroImage": "https://files.supergreatestparty.com/events/hma_2024.jpg",
      "secondaryImage": "",
      "textBlock": "",
      "id": "5e1f9c4b-7a3d-49b8-9d2f-0c6b7a9e4f1d",
      "flyer": "https://files.supergreatestparty.com/events/Baltimore.pdf"
    },
    {
      "micrositeId": "hma",
      "code": "newyorkhma",
      "name": "The Healing Power of Hispanic Food",
      "tagline": "",
      "location": "Terrace on the Park - Regency Ballroom",
      "address": {
        "street": "52-11 111th St",
        "city": "Queens",
        "state": "NY",
        "zip": "11368"
      },
      "startTimestamp": 1729314000,
      "endTimestamp": 1729314000,
      "sessions": [
        {
          "name": "Morning Session",
          "times": "10:30 a.m. - 12:30 p.m."
        },
        {
          "name": "Afternoon Session",
          "times": "2:30 p.m. - 4:30 p.m."
        }
      ],
      "featuring": [
        {
          "name": "Dana Stanglmaier",
          "details": ""
        }
      ],
      "heroImage": "https://files.supergreatestparty.com/events/hma_2024.jpg",
      "secondaryImage": "",
      "textBlock": "",
      "id": "8f3b9d7c-2e4a-4d1c-9a5f-0b6d7e9f3c1a",
      "flyer": "https://files.supergreatestparty.com/events/Baltimore.pdf"
    }
  ]
}
