<section id="bodyBlock" [class]="micrositeId">
  <div class="heroArea" style="text-align: center">
    <div [class]="micrositeId" role="banner" id="bannerImage">
      <img [src]="
      micrositeId === 'aby' ? 'https://files.supergreatestparty.com/events/blank_bg_640_363.png' : 'https://files.supergreatestparty.com/events/blank_bg_730_567.png'
      " />
    </div>
  </div>
  <div class="container" style="text-align: center" id="bannerText">
    <div class="text-center">
      <div class="form-group">
        <img
          *ngIf="eventDetails.secondaryImage"
          [src]="eventDetails.secondaryImage"
          style="
            float: left;
            width: 100%;
            margin: 20px auto;
            max-height: 200px;
            max-width: fit-content;
          "
        />
        <h1 class="navbar-brand">{{ eventDetails.name }}</h1>
        <p class="tagline">{{ eventDetails.tagline }}</p>
      </div>
      <div class="form-group"></div>
    </div>
  </div>
  <div class="container middleContent">
    <div class="">
      <div class="form-group">
        <p class="date">
          <span class="dateString">{{
            eventDetails.dateString
          }}</span>
        </p>
        <div class="row">
          <div class="form-group col-md-12 col-sm-12">
            <p class="address">
              <span>Venue</span><br />
              {{ eventDetails.location }}<br />
              {{
                eventDetails.address.street.length > 0
                  ? eventDetails.address.street + ","
                  : ""
              }}<br />
              {{
                eventDetails.address.city.length > 0
                  ? eventDetails.address.city + ","
                  : ""
              }}
              {{ eventDetails.address.state }} {{ eventDetails.address.zip
              }}<br />
            </p>
          </div>
          <!-- <div class="form-group  col-md-6 col-sm-12">
                        <div class="featuring">
                            <p>Featuring</p>
                            <span *ngFor="let feature of eventDetails.featuring;">{{feature.name}}</span>
                        </div>
                    </div> -->
        </div>
      </div>
    </div>
  </div>
  <section id="sessions" class="page-section" *ngIf="eventDetails.sessions">
    <div class="container sessionBlock">
      <h2>
        Which event time would you like to attend? <span>(required)</span>
      </h2>
      <form class="container" [formGroup]="registrationForm" novalidate>
        <ul>
          <li *ngFor="let session of eventDetails.sessions">
            <div class="form-group col-md-12 col-sm-12">
              <input
                type="radio"
                name="session"
                [value]="'session' + session.name.split(' ').join('')"
                [id]="'session' + session.name.split(' ').join('')"
                formControlName="session"
              /><label [for]="'session' + session.name.split(' ').join('')"
                >{{ session.name }} - {{ session.times }}</label
              >
            </div>
          </li>
        </ul>
      </form>
    </div>
  </section>
  <section class="page-section" id="registration">
    <div class="container regBlock">
      <div class="row text-center">
        <br />
        <br />
        <h3 class="error col-md-12">
          <span class="req">*</span> indicates a required field.
        </h3>
        <h3 class="error" *ngIf="showHttpError">
          There was an issue submitting your data, please try again.
        </h3>
        <form
          class="container"
          [formGroup]="registrationForm"
          (submit)="registerGuest()"
          novalidate
        >
          <div class="row" style="width: 100%">
            <div class="form-group col-md-4 col-sm-12">
              <label for="firstName">Prefix</label>
              <select
                class="form-control"
                name="prefix"
                id="prefix"
                formControlName="prefix"
                style="width: 100%"
              >
                <option value=""></option>
                <option value="Ms">Ms</option>
                <option value="Miss">Miss</option>
                <option value="Mrs">Mrs</option>
                <option value="Mr">Mr</option>
                <option value="Master">Master</option>
                <option value="Fr">Father (Fr)</option>
                <option value="Rev">Reverend (Rev)</option>
                <option value="Dr">Doctor (Dr)</option>
                <option value="Atty">Attorney (Atty)</option>
                <option value="Hon">Honorable (Hon)</option>
                <option value="Prof">Professor (Prof)</option>
                <option value="Pres">President (Pres)</option>
                <option value="VP">Vice President (VP)</option>
                <option value="CO">Chief Officer (CO)</option>
                <option value="CTO">Cheif Technical Officer (CTO)</option>
                <option value="CEO">Cheif Executive Officer (CEO)</option>
                <option value="COO">Cheif operating Officer (COO)</option>
                <option value="Gov">Governor (Gov)</option>
                <option value="Ofc">Officer (Ofc)</option>
                <option value="Capt">Captain</option>
                <option value="Chief">Chief</option>
                <option value="Cmdr">Commander</option>
                <option value="Col">Colonel</option>
                <option value="Gen">General</option>
                <option value="Gov">Governor</option>
                <option value="Maj">Major</option>
                <option value="MSgt">Major/Master Sergeant</option>
                <option value="Prince">Prince</option>
              </select>
            </div>
            <div class="form-group col-md-8 col-sm-12">
              <label for="firstName"
                >First Name<span class="req">*</span></label
              >
              <input
                type="text"
                class="form-control"
                placeholder="Enter first name"
                name="firstName"
                id="firstName"
                formControlName="firstName"
                style="width: 100%"
              />
              <div
                class="help-block ng-hide email_empty"
                *ngIf="
                  formSubmitted && !registrationForm.controls['firstName'].valid
                "
              >
                <div
                  class="error-block"
                  *ngIf="registrationForm.controls['firstName'].errors.required"
                >
                  Please fill out this field.
                </div>
                <div
                  class="error-block"
                  *ngIf="
                    registrationForm.controls['firstName'].errors.minlength
                  "
                >
                  First name must contain at least two letters.
                </div>
              </div>
            </div>
            <div class="form-group col-md-8 col-sm-12">
              <label for="lastName">Last Name<span class="req">*</span></label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter last name"
                name="lastName"
                id="lastName"
                formControlName="lastName"
                style="width: 100%"
              />
              <div
                class="help-block ng-hide email_empty"
                *ngIf="
                  formSubmitted && !registrationForm.controls['lastName'].valid
                "
              >
                <div
                  class="error-block"
                  *ngIf="registrationForm.controls['lastName'].errors.required"
                >
                  Please fill out this field.
                </div>
                <div
                  class="error-block"
                  *ngIf="registrationForm.controls['lastName'].errors.minlength"
                >
                  Last name must contain at least two letters.
                </div>
              </div>
            </div>
            <div class="form-group col-md-4 col-sm-12">
              <label for="lastName">Suffix</label>
              <input
                type="text"
                class="form-control"
                placeholder=""
                name="suffix"
                id="suffix"
                formControlName="suffix"
                style="width: 100%"
              />
            </div>
          </div>
          <div class="row" style="width: 100%">
            <div class="form-group col-md-8 col-sm-12">
              <label for="email">Email<span class="req">*</span></label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter email address"
                name="email"
                id="email"
                formControlName="email"
                style="width: 100%"
              />
              <div
                class="help-block ng-hide email_empty"
                *ngIf="
                  formSubmitted && !registrationForm.controls['email'].valid
                "
              >
                <div
                  class="error-block"
                  *ngIf="registrationForm.controls['email'].errors.required"
                >
                  Please fill out this field.
                </div>
                <div
                  class="error-block"
                  *ngIf="registrationForm.controls['email'].errors.email"
                >
                  Please provide a properly formatted email address.
                </div>
              </div>
            </div>

            <div class="form-group col-md-4 col-sm-12">
              <label for="zipCode">Zip Code<span class="req">*</span></label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter zip code"
                name="zipCode"
                id="zipCode"
                formControlName="zipCode"
                maxlength="5"
                style="width: 100%"
              />
              <div
                class="help-block ng-hide email_empty"
                *ngIf="
                  formSubmitted && !registrationForm.controls['zipCode'].valid
                "
              >
                <div class="error-block">Please fill out this field.</div>
              </div>
              <div
                class="help-block ng-hide email_empty"
                *ngIf="
                  formSubmitted &&
                  (registrationForm.controls['zipCode'].value.toString()
                    .length > 5 ||
                    registrationForm.controls['zipCode'].value.toString()
                      .length < 5)
                "
              >
                <div class="error-block">
                  Please enter a valid 5 digit zip code.
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="width: 100%">
            <input type="hidden" formControlName="eventId" name="eventId" />
            <input
              type="hidden"
              formControlName="micrositeId"
              name="micrositeId"
            />
          </div>
          <div class="row">
            <div class="form-group">
              <input
                type="submit"
                class="btn btn-primary btn-md"
                value="Submit"
              />
              <input
                type="submit"
                id="reg-cancel-btn"
                class="btn btn-outline-primary btn-md"
                (click)="resetForm()"
                value="Cancel"
              />
            </div>
          </div>
        </form>
      </div>

      <!-- <div class="container regBlock success" *ngIf="showSuccess">
        <div class="row text-center">
          <div class="container">
            <h2>You're signed up!</h2>
          </div>
        </div>
      </div> -->

      <div class="row formDisclaimer">
        <p>
          Participation is voluntary. The information provided is for
          informational purposes only. Information you provide will be used for
          utilization and participation analysis, in accordance with applicable
          law.
        </p>

        <p>
          By providing your address, phone number and/or email address, you are
          agreeing to receive information and be contacted by UnitedHealthcare
          Insurance Company and its affiliates (UnitedHealthcare) or third
          parties acting on UnitedHealthcare’s behalf. For more information, see
          our
          <a
            href="https://interlex.com/privacy-policy/"
            target="_blank"
            class="outsideLink"
            >Privacy Policy</a
          >.
        </p>
      </div>
    </div>
  </section>
</section>

<div
  class="modal"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  [class]="showSuccess ? 'modalOn' : 'modalOff'"
>
<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h4 class="modal-title">You're signed up!</h4>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="returnHome()">Return to homepage</button>
      </div>
    </div>
  </div>
</div>