import { Injectable } from '@angular/core';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }


  getFormValidationErrors(form: UntypedFormGroup) {
    const formErrors = [];
    Object.keys(form.controls).forEach(key => {
      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          formErrors.push({
            error: true,
            key: key,
            keyError: keyError,
            message: `Error: ${key} is ${keyError}`
          });
        });
      }
    });
    return formErrors;
  }

}
