import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
declare var $: any;
import { GuestService } from '../guest.service';
import { GoogleAnalyticsService } from '../google-analytics.service';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  public hideBranding = false;
  public aarpImageUrl = './assets/img/aarp_logo.png';
  public aarpImageUrlTX = './assets/img/aarp_logo_tx.png';
  public siteTitle = environment.siteTitle;
  public sharePageCode = environment.pageCodes.share;
  public pageurl = environment.url;
  public facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${this.pageurl}&text=This%20is%20cool,%20you%20gotta%20see%20it!%20Sign%20up%20and%20watch.`;
  public twitterUrl = `https://twitter.com/intent/tweet?url=${this.pageurl}&text=This%20is%20cool,%20you%20gotta%20see%20it!%20Sign%20up%20and%20watch.`;
  public emailUrl = `mailto:?&subject=I wanted you to see this site&body=This%20is%20cool,%20you%20gotta%20see%20it!%20Sign%20up%20and%20watch.%20${this.pageurl}`;

  public showShare = false;
  public showRegister = false;
  public hideSignup = false;
  public showModal = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private guestService: GuestService,
    private ga: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    if (this.guestService.checkGuest()) {
      this.hideSignup = true;
    }
    this.guestService.registerModal.subscribe((sub) => {
      const openModal = sub;
      if (openModal) {
        this.launchRegisterModel();
      } else {
        this.hideRegistrationModal();
      }
      if (this.guestService.checkGuest()) {
        this.hideSignup = true;
      }
    });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe(
      (event) => {
        $('#navbarResponsive').removeClass('show');
        this.hideBranding = event.hideBranding;
      }
    );

    $(document).delegate('.modalBG', 'click', () => {
      this.closeModal();
    });
    $(document).on('keyup', (e) => {
      if (e.key === 'Escape') {
        this.closeModal();
      }
    });

    $(document).scroll(() => {
      let scrollDistance = $(document).scrollTop();
      if (scrollDistance > 100) {
        $('.scroll-to-top').fadeIn();
      } else {
        $('.scroll-to-top').fadeOut();
      }

      scrollDistance = $(document).scrollTop();
      if (scrollDistance < 100) {
        $('.scroll-down').fadeIn();
      } else {
        $('.scroll-down').fadeOut();
      }
    });

    function navbarCollapse() {

    }
    // Collapse now if page is not at top
    navbarCollapse();
    // Collapse the navbar when page is scrolled
    $(window).scroll(() => {
      if ($('#mainNav').offset().top > 100) {
        $('#mainNav').addClass('navbar-shrink');
      } else {
        $('#mainNav').removeClass('navbar-shrink');
      }
    });

  }

  closeModal() {
    this.showShare = false;
    this.showModal = false;
    this.guestService.registerModal.next(false);
    return false;
  }

  launchShareModel() {
    $('html, body').animate({
      scrollTop: 0
    }, 100);
    this.showShare = true;
    this.showModal = true;

    this.ga.eventEmitter({
      eventCategory: 'Share',
      eventAction: 'Launch Modal'
    });

    return false;
  }

  launchRegisterModel() {
    $('html, body').animate({
      scrollTop: 0
    }, 100);
    this.showModal = true;
    this.showRegister = true;
    $('.registrationPageCode').show();
    return false;
  }

  hideRegistrationModal() {
    this.showModal = false;
    this.showRegister = false;
    $('.registrationPageCode').hide();
  }

  copyToClipBoard() {
    const currentURLInput: any = $('#copy-url-input').val();
    navigator.clipboard.writeText(currentURLInput).then(() => {
    }, (err) => {
    });
  }

  goRegistration() {
    if (this.router.url === '/' || this.router.url === '/#registration') {
      window.location.href = '/#registration';
    } else {
      this.guestService.registerModal.next(true);
    }
  }
  checkNav() {
    this.scrollToTop();
  }
  scrollToTop() {
    $('html, body').animate({
      scrollTop: 0
    }, 100);
  }

}
