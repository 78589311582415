import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Guest, GuestService } from 'src/app/common/guest.service';
import { ValidationService } from 'src/app/common/validation.service';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from 'src/app/common/google-analytics.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  public registrationForm: UntypedFormGroup;
  public registrationPageCode = environment.pageCodes.registration;
  public registrationSuccessPageCode = environment.pageCodes.registrationSuccess;
  public formError: any = {
    error: false,
    message: ''
  };
  public showSuccess = false;
  public formSubmitted = false;
  public showHttpError = false;

  public googleCampaign = {
    source: '',
    medium: '',
    campaign: '',
    id: ''
  };

  public trackingId;

  public showCode = true;
  constructor(
    private validation: ValidationService,
    private guestService: GuestService,
    public ga: GoogleAnalyticsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      
      const readCookie = this.read_cookie();
      if (Object.keys(params).length != 0 && readCookie == false) {
        this.trackingId = params.utm_campaign ? params.utm_campaign : '';
        this.googleCampaign = {
          source: params.utm_source ? params.utm_source : '',
          medium: params.utm_medium ? params.utm_medium : '',
          campaign: params.utm_campaign ? params.utm_campaign : '',
          id: params.utm_id ? params.utm_id : ''
        };
        this.setCookie(this.googleCampaign);
      }
    });

    this.registrationForm = new UntypedFormGroup({
      email: new UntypedFormControl('', {
        validators: [Validators.email, Validators.required]
      }),
      firstName: new UntypedFormControl('', {
        validators: [Validators.required, Validators.minLength(2)]
      }),
      lastName: new UntypedFormControl('', {
        validators: [Validators.required, Validators.minLength(2)]
      }),
      zipcode: new UntypedFormControl('', [
        Validators.required
      ]),
      tracking_source: new UntypedFormControl(this.googleCampaign.source),
      tracking_medium: new UntypedFormControl(this.googleCampaign.medium),
      tracking_campaign: new UntypedFormControl(this.googleCampaign.campaign),
      tracking_id: new UntypedFormControl(this.googleCampaign.id),
      tracking_campaign_id: new UntypedFormControl(this.googleCampaign.campaign)
    });
    this.registrationForm.valueChanges.subscribe(x => {
      this.formSubmitted = false;
    });
    if (this.guestService.checkGuest()) {
      this.showSuccess = true;
    }
  }

  resetForm() {
    this.registrationForm.reset();
    this.formSubmitted = false;
    return false;
  }

  registerGuest() {
    this.formSubmitted = true;

    if (!this.registrationForm.valid) {
      const formError = this.validation.getFormValidationErrors(this.registrationForm);
      if (formError.length >= 1) {
        this.formError = {
          error: true,
          message: formError[0].message
        };
      } else {
        this.formError = {
          error: true,
          message: 'There is an issue signing up, please check the fields and try again.'
        };
      }
      return;
    }
    // tslint:disable-next-line:max-line-length
    if ((this.registrationForm.controls.zipcode.value).toString().length > 5 || (this.registrationForm.controls.zipcode.value).toString().length < 5) {
      return;
    }
    this.guestService.saveGuest(this.registrationForm.value).subscribe({
      next: data => {
        this.showSuccess = true;
        this.ga.eventEmitter({
          eventCategory: 'Registration',
          eventAction: 'Success',
          eventLabel: this.registrationForm.value.zipcode
        });
      },
      error: error => {
        this.ga.eventEmitter({
          eventCategory: 'Registration',
          eventAction: 'Error',
          eventLabel: error
        });
        this.showHttpError = true;
        window.setTimeout(() => {
          this.showHttpError = false;
        }, 5000);
      }
    });

  }

  read_cookie() {
    var result = false;
    if (result) {
      this.googleCampaign = JSON.parse(result);
      return true;
    } else {
      return false;
    }
  }

  setCookie(googleCampaignDetails) {
    const cookieLifetime = 30;
    const cvalue = '1';
    const d = new Date();
    d.setTime(d.getTime() + cookieLifetime * 24 * 60 * 60 * 1000);
    const expires = d;
  }

}
