import { Component, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { environment } from "../../../environments/environment";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { EventDetails, EventsService } from "src/app/common/events.service";
import { GuestService } from "src/app/common/guest.service";
import { ValidationService } from "../../common/validation.service";
import { GoogleAnalyticsService } from "src/app/common/google-analytics.service";
declare var $: any;
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  public env = environment;
  public registrationForm: FormGroup;
  public sub: any;
  public events;
  public siteTitle: string;
  public shortCode: string = "";
  public showSuccess = false;
  public formSubmitted = false;
  public showHttpError = false;

  public eventDetails: EventDetails = {
    id: "",
    code: "",
    name: "",
    tagline: "",
    location: "",
    address: {
      street: "",
      city: "",
      state: "",
      zip: "",
    },
    micrositeId: "",
    startTimestamp: 0,
    endTimestamp: 0,
    startTime: "",
    endTime: "",
    textBlock: "",
    featuring: [],
    heroImage: "/assets/img/aby_1.jpg",
    secondaryImage: "",
    sessions: [],
  };
  public trackingId;
  public micrositeId: any = "";
  public eventId: any;
  public fetch = false;
  public googleCampaign = {
    source: "",
    medium: "",
    campaign: "",
    id: "",
  };

  public formError: any = {
    error: false,
    message: "",
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private eventService: EventsService,
    private guestService: GuestService,
    private validation: ValidationService,
    private titleService: Title,
    public ga: GoogleAnalyticsService
  ) {
    this.makeForm();
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      if (params.shortCode) {
        this.shortCode = params.shortCode;
        this.eventService.fetchEvent(this.shortCode.toLowerCase()).subscribe(
          (suc) => {
            this.eventDetails = suc;
            this.titleService.setTitle(this.eventDetails.name);
            this.micrositeId = this.eventDetails.micrositeId;
            this.eventId = this.eventDetails.id;

            this.registrationForm.controls["eventId"].setValue(this.eventId);
            this.registrationForm.controls["micrositeId"].setValue(
              this.micrositeId
            );
            this.registrationForm.controls["session"].setValue('sessionMorningSession');
          },
          (err) => {
            this.eventDetails = this.eventService.getEvent(
              this.shortCode.toLowerCase()
            );
            this.titleService.setTitle(this.eventDetails.name);
            this.micrositeId = this.eventDetails.micrositeId;
            this.eventId = this.eventDetails.id;
            this.registrationForm.controls["eventId"].setValue(this.eventId);
            this.registrationForm.controls["micrositeId"].setValue(
              this.micrositeId
            );
            this.registrationForm.controls["session"].setValue('sessionMorningSession');            
          }
        );
      }
    });
  }

  makeForm() {
    this.registrationForm = new FormGroup({
      prefix: new FormControl(""),
      firstName: new FormControl("", {
        validators: [Validators.required],
      }),
      lastName: new FormControl("", {
        validators: [Validators.required],
      }),
      suffix: new FormControl(""),
      email: new FormControl("", {
        validators: [Validators.email, Validators.required],
      }),
      zipCode: new FormControl("", [Validators.required]),
      eventId: new FormControl(this.eventId),
      micrositeId: new FormControl(this.micrositeId),
      session: new FormControl("sessionMorningSession"),
    });

    this.registrationForm.valueChanges.subscribe((x) => {
      this.formSubmitted = false;
    });
  }

  resetForm() {
    this.registrationForm.reset();
    this.formSubmitted = false;
    return false;
  }

  registerGuest() {
    this.formSubmitted = true;

    if (!this.registrationForm.valid) {
      return;
    }
    // tslint:disable-next-line:max-line-length
    if (
      this.registrationForm.controls.zipCode.value.toString().length > 5 ||
      this.registrationForm.controls.zipCode.value.toString().length < 5
    ) {
      return;
    }
    this.registrationForm.controls.zipCode.setValue(
      this.registrationForm.controls.zipCode.value.toString()
    );
    this.guestService.saveGuest(this.registrationForm.value).subscribe({
      next: (data) => {
        this.showSuccess = true;
        this.resetForm();
      },
      error: (error) => {
        this.showHttpError = true;
        window.setTimeout(() => {
          this.showHttpError = false;
        }, 5000);
      },
    });
  }

  closeModal() {
    this.showSuccess = false;
  }
  returnHome() {
    this.closeModal();
    if (this.micrositeId ==='aby') {
      window.location.href = 'https://abetteryou.info';
    } else {
      window.location.href = 'https://haymasadelante.com';
    }
  }



}
