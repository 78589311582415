import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from '../google-analytics.service';
declare var $: any;

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {
  @Output() showLocation = new EventEmitter<boolean>();
  @Output() sendState = new EventEmitter<string>();
  public siteTitle = environment.siteTitle;
  public allowedStates = environment.states;
  public showNotAllowed = false;

  constructor(
    public ga: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.getCookie();
  }

  checkState() {
    const state = $('#state option:selected').text();
    this.locationChecker(state);
  }

  locationChecker(state) {
    let allowed = false;
    this.showNotAllowed = false;
    for (const match of this.allowedStates) {
      if (match === state) {
        allowed = true;
      }
    }
    if (allowed) {
      this.setCookie(state);
      this.showLocation.emit(true);
      this.sendState.emit(state);
      this.showNotAllowed = false;
      this.ga.eventEmitter({
        eventName: 'Set Location',
        eventCategory: 'Location',
        eventAction: 'Allowed',
        eventLabel: state
      });
    } else {
      this.showLocation.emit(false);
      this.showNotAllowed = true;
      this.ga.eventEmitter({
        eventName: 'Set Location',
        eventCategory: 'Location',
        eventAction: 'NotAllowed',
        eventLabel: state
      });
    }
  }



  getCookie() {
    const loc = false;
    if (loc) {
      this.locationChecker(loc);
    }
  }

  setCookie(state) {
    const cookieLifetime = 30;
    const cvalue = '1';
    const d = new Date();
    d.setTime(d.getTime() + cookieLifetime * 24 * 60 * 60 * 1000);
    const expires = d;
  }

}
