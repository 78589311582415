<nav class="navbar navbar-expand-lg bg-secondary fixed-top" id="mainNav">
    <div class="container" id="home">
        <div class="row BannerHeaderRow">
            <div class="branding-title">
                <h1 class="navbar-brand">{{siteTitle}}</h1>
            </div>
            <div class="branding-image" [hidden]="hideBranding">
                <a class="" [routerLink]="['/']">
                    <img id="branding_uhg" src="{{aarpImageUrl}}" alt="AARP Medicare Supplement from UnitedHealthcare" />
                </a>
            </div>

            <button class="navbar-toggler navbar-toggler-right text-uppercase font-weight-bold bg-primary rounded" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                <svg class="svg-inline--fa fa-bars fa-w-14" aria-hidden="true" focusable="false" data-prefix="fas"
                    data-icon="bars" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                    data-fa-i2svg="">
                    <path fill="currentColor"
                        d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z">
                    </path>
                </svg>
            </button>
        </div>
        <div class="row nav-item-list w-100">
            <div class="collapse navbar-collapse" id="navbarResponsive" role="navigation" aria-label="Main">
                <ul class="navbar-nav">
                    <li class="nav-item mx-0 mx-lg-1">
                        <a class="nav-link" [routerLink]="['/']" fragment="Home" [routerLinkActive]="['active']" queryParamsHandling="merge" [routerLinkActiveOptions]="{exact:
                            true}" (click)="checkNav()">
                            Home
                        </a>
                    </li>
                    <li class=" nav-item mx-0 mx-lg-1 ">
                        <a class="nav-link" [routerLink]="[ '/inspiration'] " [routerLinkActive]="[ 'active']" queryParamsHandling="merge" [routerLinkActiveOptions]="{exact:
                            true}"> Medicare Education & Inspiration
                        </a>
                    </li>
                    <li class="nav-item mx-0 mx-lg-1">
                        <a class="nav-link" [routerLink]="['/virtualtakehomebag']" [routerLinkActive]="['active']" queryParamsHandling="merge" [routerLinkActiveOptions]="{exact:
                            true}">
                            Virtual Take Home Bag
                        </a>
                    </li>
                </ul>
                <ul class=" navbar-nav ml-auto " id="authNav ">
                    <li class="nav-item " *ngIf="!hideSignup">
                        <a class="nav-link signUp" (click)="goRegistration()">
                            Sign Up
                        </a>
                    </li>
                    <li class="nav-item noBorder">
                        <a class="nav-link" href="#" (click)="launchShareModel()">
                            <img src="../../../assets/img/share.svg" class="shareIcon" alt="Click to Share Page" />
                        </a>
                        <div class="sharePointer" role="dialog">
                            Share Page
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>

<div class="modalBG" *ngIf="showModal"></div>
<div id="share-modal" class="customModel" tabindex="-1" role="dialog" aria-label="Share Content" aria-hidden="true" *ngIf="showShare">
    <h2>Share with family and friends!</h2>
    <a href="#" class="closeBtn iconLink" (click)="closeModal()"><img src="../../../assets/img/close_icon.svg" alt="Close Share Modal" /></a>
    <div class="row text-center">
        <div class="container share-container">
            <a [href]="facebookUrl" class="iconLink" target="_blank"><img src="../../../assets/img/fb_share.svg" alt="Share to Facebook" /></a>
            <a [href]="twitterUrl" class="iconLink" target="_blank"><img src="../../../assets/img/twitter_share.svg" alt="Share to Twitter" /></a>
            <a [href]="emailUrl" class="iconLink"><img src="../../../assets/img/email_share.svg" alt="Share to Email" /></a>
            <div class="copyBox">
                <label for="copy-url-input">Page URL</label>
                <input type="text" class="copy-url-text" id="copy-url-input" [value]="pageurl" />
                <a class="copyBtn iconLink" (click)="copyToClipBoard()">
                    <img src="../../../assets/img/copy_share.svg" alt="Copy Page URL" />
                    <div class="sharePointer" role="dialog">
                        Copy
                    </div>
                </a>

            </div>
            <div class="code">
                {{sharePageCode}}ST
            </div>
        </div>
    </div>
</div>


<div id="register-modal" class="customModel" tabindex="-1" role="dialog" aria-label="Register for access" aria-hidden="true" *ngIf="showRegister">
    <a href="#" class="closeBtn iconLink" (click)="closeModal()"><img src="../../../assets/img/close_icon.svg" alt="Close Share Modal" /></a>
    <app-registration></app-registration>
</div>