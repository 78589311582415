export const environment = {
  mockApi: false,
  production: true,
  activeDomain: '',
  url: '',
  site: '',
  siteTitle: '',
  gaTrackingCode: '',
  states: [
  ],
  pageCodes: {
    home: 'WB27498ST',
    registration: 'MO10062ST',
    registrationSuccess: 'MO10063ST',
    share: 'MO10064ST',
    videos: 'WB27499ST',
    materials: 'WB27501ST'
  },
  backgroundImageUrl: 'https://files.supergreatestparty.com/events/blank_bg_730_567.png'
};
