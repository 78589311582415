<div class="container regBlock" *ngIf="!showSuccess">
    <div class="row text-center">
        <h3 class="error" *ngIf="showHttpError">There was an issue submitting your data, please try again.</h3>
        <form class="container" [formGroup]="registrationForm" (submit)="registerGuest()" novalidate>
            <div class="row" style="width: 100%;">
                <div class="form-group">
                    <label for="firstName">First Name (required)</label>
                    <input type="text" class="form-control" placeholder="Enter first name" name="firstName" id="firstName" formControlName="firstName" />
                    <div class="help-block ng-hide email_empty" *ngIf="formSubmitted && !registrationForm.controls['firstName'].valid">
                        <div class="error-block" *ngIf="registrationForm.controls['firstName'].errors.required">
                            Please fill out this field.
                        </div>
                        <div class="error-block" *ngIf="registrationForm.controls['firstName'].errors.minlength">
                            First name must contain at least two letters.
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="lastName">Last Name (required)</label>
                    <input type="text" class="form-control" placeholder="Enter last name" name="lastName" id="lastName" formControlName="lastName" />
                    <div class="help-block ng-hide email_empty" *ngIf="formSubmitted && !registrationForm.controls['lastName'].valid">
                        <div class="error-block" *ngIf="registrationForm.controls['lastName'].errors.required">
                            Please fill out this field.
                        </div>
                        <div class="error-block" *ngIf="registrationForm.controls['lastName'].errors.minlength">
                            Last name must contain at least two letters.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="width: 100%;">
                <div class="form-group">
                    <label for="zipcode">Zip Code (required)</label>
                    <input type="number" class="form-control" placeholder="Enter zip code" name="zipcode" id="zipcode" formControlName="zipcode" maxlength="5" />
                    <div class="help-block ng-hide email_empty" *ngIf="formSubmitted && !registrationForm.controls['zipcode'].valid">
                        <div class="error-block">
                            Please fill out this field.
                        </div>
                    </div>
                    <div class="help-block ng-hide email_empty" *ngIf="formSubmitted && ((registrationForm.controls['zipcode'].value).toString().length > 5 || (registrationForm.controls['zipcode'].value).toString().length < 5)">
                        <div class="error-block">
                            Please enter a valid 5 digit zip code.
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="email">Email (required)</label>
                    <input type="text" class="form-control" placeholder="Enter email address" name="email" id="email" formControlName="email" />
                    <div class="help-block ng-hide email_empty" *ngIf="formSubmitted && !registrationForm.controls['email'].valid">
                        <div class="error-block" *ngIf="registrationForm.controls['email'].errors.required">
                            Please fill out this field.
                        </div>
                        <div class="error-block" *ngIf="registrationForm.controls['email'].errors.email">
                            Please provide a properly formatted email address.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <input type="hidden" name="tracking" id="tracking" formControlName="tracking_campaign_id" />
                <div class="form-group">
                    <input type="submit" class="btn btn-primary btn-md" value="Submit">
                    <input type="submit" id="reg-cancel-btn" class="btn btn-outline-primary btn-md" (click)="resetForm()" value="Cancel">
                </div>
            </div>
        </form>
    </div>
    <div class="row formDisclaimer">
        <p>
            Participation is voluntary. The information provided is for informational purposes only. Information you provide will be used for utilization and participation analysis, in accordance with applicable law.
        </p>
        <p>
            By providing your address, phone number and/or email address, you are agreeing to receive information and be contacted by UnitedHealthcare Insurance Company and its affiliates (UnitedHealthcare) or third parties acting on UnitedHealthcare’s behalf.
        </p>
        <p>
            For more information, see our <a href="https://www.aarpmedicaresupplement.com/privacypolicy.html" target="_blank" class="outsideLink">Privacy Policy <svg _ngcontent-agh-c15="" viewBox="0, 0, 24, 24"
                    focusable="false" role="presentation" shape-rendering="geometricPrecision"
                    xmlns="http://www.w3.org/2000/svg">
                    <path _ngcontent-agh-c15="" fill="#272727"
                        d="M20.09 1.99H5.84v2.79H16.5L3.03 20.17l2.1 1.84L18.17 7.1v9.87h2.79V1.99z"></path>
                </svg></a>.
        </p>
    </div>
</div>

<div class="container regBlock success" *ngIf="showSuccess">
    <div class="row text-center">
        <div class="container">
            <h2>You're signed up!</h2>
            <a class="btn btn-primary" [routerLink]="[ '/inspiration']" queryParamsHandling="merge" (click)="getStarted()">Let's Get Started</a>
        </div>
    </div>
    <div class="row formDisclaimer code">
        <p>
            {{registrationSuccessPageCode}}ST
        </p>
    </div>
</div>