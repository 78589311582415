import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
export interface Guest {
  id: string;
  firstName: string;
  lastName: string;
  zipcode: number;
  email: string;
  trackingCode: string;
  sessions: string;
}

@Injectable({
  providedIn: 'root'
})
export class GuestService {
  public guest: Guest = {
    id: '0000',
    firstName: '',
    lastName: '',
    zipcode: 0,
    email: '',
    trackingCode: '',
    sessions:''
  };
  public registerModal = new BehaviorSubject(false);
  // public api = 'https://abetteryou-api.azurewebsites.net';
  public api = '';
  constructor(private http: HttpClient) { }

  saveGuest(guestData: Guest): Observable<Response> {
    return this.http.post<Response>(this.api + '/users', guestData);
  }

  guestLookup(guestData: Guest): Observable<Guest> {
    const guestId = '';
    return this.http.get<Guest>(this.api + `/users/${guestId}`);
  }

  updateGuestTracking(guestData: Guest): Observable<Guest> {
    return this.http.put<Guest>(this.api + '/api/guest', guestData);
  }

  checkGuest() {
    return false;    
  }

  getToken() {
    // return this.http.get(this.api + `/auth/start`).subscribe( response => {
    //   return response;
    // });
  }


}
