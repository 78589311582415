import { Injectable } from "@angular/core";
import * as staticEvents from "./staticEvents.json";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject, of } from "rxjs";
import { environment } from "src/environments/environment";

export interface EventDetails {
  id?: string;
  code?: string;
  name?: string;
  tagline?: string;
  location?: string;
  address?: EventAddress;
  startTimestamp?: number;
  endTimestamp?: number;
  startTime?: string;
  endTime?: string;
  sessions?: EventSessions[];
  featuring?: EventFeaturing[];
  micrositeId?: string;
  timestampCreated?: number;
  heroImage?: string;
  secondaryImage?: string;
  dateString?: string;
  textBlock?: string;
  flyer?: string;
  active?: boolean;
  _attachments?: any;
  _etag?: any;
  _rid?: any;
  _self?: any;
  _ts?: any;
}
export interface EventAddress {
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
}
export interface EventFeaturing {
  name?: string;
  text?: string;
  details?: string;
}
export interface EventSessions {
  name?: string;
  times?: string;
}

@Injectable({
  providedIn: "root",
})
export class EventsService {
  public eventsList = staticEvents;
  public url = "/api/events/";
  // public api = 'https://abetteryou-api.azurewebsites.net';
  public api = "";
  public mockApi = environment.mockApi;

  constructor(private http: HttpClient) {}

  fetchEvent(shortCode): Observable<EventDetails> {
    if (this.mockApi) {
      const mockData: EventDetails = this.eventsList.data.filter(
        (event) => event.code.toLowerCase() === shortCode.toLowerCase()
      )[0];
      return of(mockData); // Mock API returns a static JSON object
    } else {
      return this.http.get<EventDetails>(this.api + this.url + `${shortCode}`);
    }
  }

  getEvent(eventCode: string): any {
    return this.eventsList.data.filter(
      (event) => event.code.toLowerCase() === eventCode.toLowerCase()
    )[0];
  }
}
