import { Injectable } from '@angular/core';

declare let gtag: Function;

export interface GoogleTag {
  eventName?: string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
  eventValue?: number;
}
@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  public eventEmitter(event: GoogleTag) {
    // gtag('event', event.eventAction, {
    //   event_category: event.eventCategory,
    //   event_label: event.eventLabel
    // });
  }
}
