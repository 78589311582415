import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesModule } from './pages/pages.module';
import { CommonComponentsModule } from './common/common.module';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidationService } from './common/validation.service';
import { GuestService } from './common/guest.service';
import { GoogleAnalyticsService } from './common/google-analytics.service';

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        PagesModule,
        CommonComponentsModule,
        ReactiveFormsModule,
        FormsModule], providers: [
        ValidationService,
        GuestService,
        GoogleAnalyticsService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
